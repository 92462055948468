<template>
  <section class="cus-list">
    <el-table
      :data="tableData"
      border v-loading="loading"
      ref="table"
      style="width: 100%;margin-bottom: 40px;">
      <el-table-column  :resizable="false"
        prop="username"
        align="center"
        label="用户名"/>
      <el-table-column  :resizable="false"
        prop="email"
        align="center"
        label="邮箱"/>
      <el-table-column  :resizable="false"
        align="center"
        label="状态">
        <template scope="{ row }">
          <el-switch
            v-model="row.state"
            active-color="#13ce66"
            inactive-color="#ff4949" disabled>
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        label="操作"
        align="center">
        <template scope="{ row }">
          <!--<el-button type="text" @click="check(row.id)">查看</el-button>-->
          <el-button type="text" @click="deleteCus(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
                     @size-change="sizeChange" @current-change="currentChange" :total="count">
      </el-pagination>
    </section>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { listcusAccount, delectAccount } from '@/api/settings/cusAccount'
export default {
  mounted () {
    this.getList()
    eventHub.$emit('msg', '平台用户')
  },
  data () {
    return ({
      count: 0,
      currentPage: 1,
      size: 10,
      loading: false,
      tableData: [],
      selectedList: []
    })
  },
  methods: {
    check () {
      this.$router.push('/settings/role/usersMsg/')
    },
    async getList () {
      this.loading = true
      const res = await listcusAccount({ 'currentPage': this.currentPage, 'size': this.size })
      this.tableData = res.data.list
      this.count = res.data.count
      this.loading = false
    },
    sizeChange: async function (i) {
      this.loading = true
      this.currentPage = 1
      this.size = i
      try {
        const res = await listcusAccount({ 'currentPage': this.currentPage, 'size': this.size })
        this.tableData = res.data.list
        this.count = res.data.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    currentChange: async function (i) {
      this.currentPage = i
      this.loading = true
      try {
        const res = await listcusAccount({ 'currentPage': this.currentPage, 'size': this.size })
        this.tableData = res.data.list
        this.count = res.data.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    deleteCus (id) {
      delectAccount({ 'id': id })
      this.getList()
    }
  }
}
</script>

<style lang="scss">
  .cus-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    border-radius: 2px;
    padding: 20px 20px;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
    .selected {
      background: rgba(64,158,255, .2);
    }
    .el-dialog {
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
</style>
