import request from '@/utils/request'

export function listcusAccount (data) {
  return request({
    url: '/sys/user/listCusAccount',
    method: 'POST',
    data: data
  })
}
export function delectAccount (data) {
  return request({
    url: '/sys/user/delectCusAccount',
    method: 'POST',
    data: data
  })
}
